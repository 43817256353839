
<template>
  <div class="page">
    <head-nav />
    <div class="ss-ban" :style="{ backgroundImage: 'url(' + banner + ')' }">
      <div class="box">
        <div class="searbox">
          <input type="text" class="ipt ipt-txt" placeholder="请输入搜索内容" v-model="keyword" />
          <button class="submit" type="button" @click="submitFun()">
            搜索
          </button>
        </div>
        <div class="hot-tags">
          <router-link to="" class="lk">学院</router-link> |
          <router-link to="" class="lk">研究所</router-link> |
          <router-link to="" class="lk">科技</router-link> |
          <router-link to="" class="lk">创新技术</router-link> |
          <router-link to="" class="lk">攻城</router-link>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="auto wrap flex flex-align-start flex-w">
        <div class="ss-l flex-1 bg-white">
          <div class="ss-top">
            <span class="lk" :class="{ cur: num == index }" @click="tabFun(index, item)" v-for="(item, index) in menu" :key="index">{{ item }}</span>

            <span class="total fr">共 <em class="c-main">{{ dataCount }}</em> 条信息</span>
          </div>
          <ul class="ss-list">
            <li class="item" v-for="(item, index) in searchDetailsList" :key="index" v-show="item.ItemName!=''">
              <router-link :to="{
                            path: GetPath(item.TypeCode),
                            query: {
                              itemId: item.ItemID,
                              rootId: 10000,
                              supItemId: item.ParentID,
                              typeCode: item.TypeCode,
                            },
                          }" v-show="atPages == 1 && index < 2">
                <div class="imgbox">
                  <div class="zoomimg">
                    <img src="../../assets/images/society_03.jpg" class="img" />
                  </div>
                </div>
              </router-link>
              <div class="info">
                <h6 class="title ellipsis">
                  <router-link :to="{
                            path: GetPath(item.TypeCode),
                            query: {
                              itemId: item.ItemID,
                              rootId: 10000,
                              supItemId: item.ParentID,
                              typeCode: item.TypeCode,
                            },
                          }">{{
                    item.ItemName
                  }}</router-link>
                </h6>
                <div class="txt clamp">{{ item.SF1 }}</div>
                <p class="date">{{ item.CrDate }}</p>
              </div>
            </li>
          </ul>
          <div class="pagelist" v-show="dataCount > 10">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 15, 20, 25]" :page-size="pageSize"
              :total="dataCount" layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>
        <div class="ss-r">
          <div class="ss-recom bg-white">
            <div class="tt flex flex-pack-justify">
              <span class="bt">热搜推荐</span>
              <span class="change" @click="hutFun()">换一批</span>
            </div>
            <ul class="list">
              <div class="hotBox" v-for="(item, index) in HotList2" :key="index" v-show="item.ItemName!=''">
                <template>
                  <li class="item flex flex-align-center">
                    <em class="nums" :class="
                        index == 0
                          ? 'firstIcon'
                          : index == 1
                          ? 'secondIcon'
                          : index == 2
                          ? 'thirdlyIcon'
                          : ''
                      ">{{ index + 1 < 4 ? "" : index+1 }}</em>
                    <h6 class="title ellipsis">
                      <router-link :to="{
                            path: GetPath(item.TypeCode),
                            query: {
                              itemId: item.ItemID,
                              rootId: 10000,
                              supItemId: item.ParentID,
                              typeCode: item.TypeCode,
                            },
                          }">{{
                        item.ItemName
                      }}</router-link>
                      <img :src="
                          index == Math.round(Math.random() * 10) ? HotIcon : ''
                        " class="hot-ico" />
                    </h6>
                    <!-- <span class="date">{{item.date}}</span> -->
                  </li>
                </template>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
export default {
  name: "",
  data() {
    return {
      banner: require("../../assets/images/ss_01.jpg"),
      HotIcon: require("../../assets/images/ss_02.png"),
      menu: ["综合", "核心技术", "学术成果", "荣誉奖励"],
      num: 0,
      rootIdNum: "10000",
      searchStr: "",
      parentID: "",
      keyword: "",
      searchDetailsList: [],
      //默认第一页
      currentPage: 1,
      atPages: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 10,
      HotList2: [],
    };
  },
  components: {
    headNav,
    foot,
  },
  created() {
    let that = this;

    if (that.$route.query.keyword != "") {
      that.keyword = that.$route.query.keyword;
      that.submitFun("", "", that.keyword);
    } else {
      that.send(that.rootIdNum, "", "", that.currentPage);
    }
  },
  watch: {
    'keyword': {
      handler: function (val) {
        // console.log(val)
      },
      deep: true // 深度检测
    }
  },
  mounted() {
    let that = this;
    // 初始化加载奖励列表
    that.hutFun();
    $('#search').toggle();
    // that.optionalTitle = that.optionalLists[0];
    // that.UrlDetailRedirect();
  },

  methods: {
    tabFun(index, item) {
      let that = this;
      that.num = index;
      switch (item) {
        case "综合":
          that.parentID = " ";
          break;
        case "核心技术":
          that.parentID = "114";
          break;
        case "学术成果":
          that.parentID = "4";
          break;
        case "荣誉奖励":
          that.parentID = "5";
          break;
      }
      that.send(that.rootIdNum, that.parentID, that.keyword, that.currentPage);
    },


    //搜索
    submitFun() {
      let that = this;
      that.send("", "", that.keyword);
      that.txtSearch(that.keyword);
    },
    // 加载奖励列表
    send(rootIdNum, parentID, searchStr, currentPage) {
      // console.log(searchStr);
      let that = this;
      if (rootIdNum == "") {
        rootIdNum = that.rootIdNum;
      }
      var obj = {
        rootId: rootIdNum,
        parentID: parentID,
        searchStr: searchStr,
        pageIndex: currentPage,
        pageSize: that.pageSize,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/CommonApi/Sousuo",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          for (let i = 0; i < datas.Items.length; i++) {
            datas.Items[i].CrDate = datas.Items[i].CrDate.replace(/T/g, " ");
            // datas.Items[i].ItemName = datas.Items[i].ItemName.replace(/searchStr/g, " ");
          }
          that.searchDetailsList = datas.Items;
          console.log(datas.Items)
          that.dataCount = datas.DataCount[0].count;
        },
      });
    },
     txtSearch(param) {
      console.log(param,555555555);
      var regex = new RegExp("[" + param + "]", "g");
      $(".info>.title, div.txt").each( (index, el) => {
        console.log('搜索第一次不执行');
        var elTxt = $(el).text();
        var val = elTxt.match(regex);
        var elHtml = "";
        $.each(val, (idx, item) => {
          var lastIndex = elTxt.indexOf(item) + 1;
          elHtml += elTxt.substring(0, lastIndex).replace(item, "<em style='color: red;'>" + item + "</em>");
          elTxt = elTxt.substring(lastIndex);
        });
        $(el).html(elHtml + elTxt);
        console.log(elTxt,66666666);
        console.log(val,7777777);
      });
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let that = this;
      that.atPages = val;
      console.log(`当前页: ${val}`);
      that.send(that.rootIdNum, that.parentID, that.keyword, val);
    },
    //热门
    hutFun() {
      let that = this;

      var obj = {
        rootId: "10000",
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/CommonApi/TopData",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          that.HotList2 = datas;
          //   for(let i=0;i<datas.Items.length;i++){
          // 	  datas.Items[i].CrDate=datas.Items[i].CrDate.replace(/T/g," ")
          //   }
          //   that.searchDetailsList = datas.Items;
          //   that.dataCount = datas.DataCount[0].count;
          //   console.log(datas.Items);
          // that.searchDetailsList.CrDate=b
        },
      });
    },

    GetPath(typeCode) {
      var path = "#";
      switch (typeCode) {
        case "CX027":
        case "CX028":
        case "CX029":
        case "CX030":
        case "CX031":
        case "CX032":
          path = "/kejichuangxin"
          break;
        case "CX005":
        case "CX006":
        case "CX006":
        case "CX007":
        case "CX008":
        case "CX009":
        case "CX010":
        case "CX011":
        case "CX026":
          path = "/kejichengguo"
          break;
        case "CX012":
        case "CX013":
          path = "/kejijiangli-personal"
          break;
        case "CX016":
        case "CX017":
        case "CX018":
        case "CX019":
          path = "/rencaipeiyang"
          break;
        case "CX020":
        case "CX021":
          path = "/xueshujiaoliu"
          break;
      }
      return path;
    }




  },
};
</script>

<style>
.hotBox .nums {
  font-size: 14px;
  color: #024fa1;
  width: 18px;
  height: 18px;
  background: #e5edf5;
  border-radius: 2px;
  text-align: center;
  line-height: 18px;
}

.firstIcon {
  font-size: 0;
  background: url(../../assets/images/ss_04.png) no-repeat center !important;
  background-size: contain;
}

.secondIcon {
  font-size: 0;
  background: url(../../assets/images/ss_05.png) no-repeat center !important;

  background-size: contain;
}

.thirdlyIcon {
  font-size: 0;
  background: url(../../assets/images/ss_06.png) no-repeat center !important;

  background-size: contain;
}
</style>